<template>
	<div class="inside-page-content">
		<div class="paper">
			<div class="page">
				<div class="top" v-if="type != 3">
					<img :src="detail.pictureUrl" class="img"/>
					<div class="right">
						<div class="title">{{detail.deviceName || detail.instrumentName}}</div>
						<div class="desc"><span style="color: #999999;">所在单位：</span> {{detail.belongUnit || detail.initiateUnit}}</div>
						<div class="desc" style="margin-top: 10px;"><span style="color: #999999;">可否提供模型展示：</span>{{detail.isSupplyModel == 1 ? '是' : '否'}}</div>
						<div class="link" style="margin-top: 20px;" v-if="type == 4" @click="join">参与攻关</div>
						<div class="link" style="margin-top: 20px;" v-else @click="subscribe">立即预约</div>
					</div>
				</div>
				<div class="top" v-else>
					<img :src="detail.pictureUrl" class="img2"/>
					<div class="right">
						<div class="title">{{detail.instrumentName}}</div>
						<div class="desc">仪器型号 {{detail.instrumentModel}}</div>
						<div class="desc">仪器品牌 {{detail.instrumentBrand}}</div>
						<div class="desc">生产厂家 {{detail.instrumentFactory}}</div>
						<div class="price">¥{{detail.price}}</div>
						<div class="link" v-if="!user || !user.username" @click="contactBusiness">联系卖家</div>
					</div>
				</div>
				<div class="bar-title-text">
					<div class="bar-title">功能描述</div>
					<div class="p">
						{{detail.functionIntroduction || detail.projectSignificance}}
					</div>
				</div>
				<div class="bar-title-text">
					<div class="bar-title">联系我们</div>
					<div class="p">
						<div><span class="label">联系人</span>{{user&&user.username?detail.contactName: '***'}}</div>                            
						<div><span class="label">联系电话</span>{{user&&user.username?detail.contactPhone: '***********'}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex'
	export default {
		name: 'deviceDetail',
		components: {
			
		},
		computed:{
			...mapState({
				user: state => state.user_info,
			}),
		},
		data() {
			return {
				id: {},
				type: null,
				detail: {},
				detailTitleName: {
					0: '大科学装置共享',
					1: '大型仪器共享',
					// 2: '大科学装置共享',
					3: '仪器商城',
					4: '仪器研发'
				}
			}
		},
		created() {
			// 获取路由参数
			this.id = this.$route.query.id;
			this.type = this.$route.query.type;
			this.$route.meta.title = this.detailTitleName[this.type]
			this.getDetail();
		},
		mounted() {

		},
		methods: {
			contactBusiness() {
				this.$confirm('请登录后查看联系方式', '提示', {
					confirmButtonText: '去登陆',
					cancelButtonText: '取消',
					showClose: false,
					closeOnClickModal: false,
					type: 'warning'
				}).then(() => {
					this.$router.push("/logon");
				}).catch(() => {
					// this.$router.go(-1)          
				});
			},
			getDetail() {
				let api = {
					0: this.$api.share.deviceDetail,
					1: this.$api.share.instrumentDetail,
					// 2: this.$api.share.rdDetail,
					3: this.$api.share.marketDetail,
					4: this.$api.share.rdDetail,
				}
				api[this.type](this.id).then((res) => {
					this.detail = res.data.data
				})
			},
			join() {
				this.$router.push({
					path: '/sharedInstrument/join', 
					query: {
						id: this.id
					}
				})
			},
			subscribe() {
				this.$router.push({
					path: '/sharedInstrument/subscribe', 
					query: {
						id: this.id,
						type: this.type
					}
				})
			}
		}
	}
</script>
<style scoped lang="less">
	.page {
		padding: 20px;
    min-width: 1100px;
    max-width: 1300px;
    margin: 0 auto;
		box-sizing: border-box;
		background: #fff;
		.top {
			display: flex;
			border-bottom: 1px solid #eee;
			padding-bottom: 40px;
			margin-bottom: 40px;
			.img {
				width: 230px;
				height: 160px;
				object-fit: cover;
				margin-right: 40px;
			}
			.img2 {
				width: 230px;
				height: 190px;
				object-fit: cover;
				margin-right: 40px;
			}
			.title {
				font-size: 22px;
				font-weight: 700;
				color: #333;
				margin-bottom: 14px;
			}
			.desc {
				line-height: 1.2;
			}
			.price {
				font-size: 26px;
				font-weight: 700;
				line-height: 44px;
				color: #F1AF53;
			}
			.link {
				color: #fff;
				background: #0046C0;
				display: inline-block;
				padding: 8px 20px;
				font-size: 15px;
				cursor: pointer;
				border-radius: 15px;
			}
		}
	}
</style>
